<template>
	<Header v-if="!isPath" />
	<router-view />
	<Footer v-if="!isPath" />
	<ContactBox v-if="!isPath" ></ContactBox>
	<ReturnPage v-if="isBack" :isBack="isBack"></ReturnPage>
</template>
<script>
	// import { wexinShare } from "./assets/common/utils01.js"
	import Header from '@/components/common/Header.vue'
	import Footer from '@/components/common/Footer.vue'
	import ContactBox from '@/components/common/ContactBox.vue'
	import ReturnPage from "@/components/common/AppPublic/ReturnPage.vue";
	export default {
		data() {
			return {
				isBack: false,
				isPath:''
			}
		},
		components: {
			Header,
			Footer,
			ContactBox,
			ReturnPage
		},

		watch: {
			$route: {
				handler: function(route) {
					console.log(route)
					if (route.path == "/NewsInfor1" || route.path == "/NewsInfor2") {
						console.log(document.documentElement.scrollTop);
						document.documentElement.scrollTop = 0;
					}
					var that = this;
					that.isPath = route.meta.isPath
					if (Boolean(route.query.isBack)) {
						this.isBack = true
					} else {
						this.isBack = false
					}
				},
				immediate: true
			}
		},
		created() {
			// this.$nextTick(() => {
			// 	// 禁用右键
			// 	document.oncontextmenu = new Function("event.returnValue=false");
			// 	// 禁用选择
			// 	document.onselectstart = new Function("event.returnValue=false");
			// })
			let imgList = []
			const files = require.context("./assets/logs", true,/\.*\.jpg|jpeg|png$/).keys();
			for (let i = 0; i < files.length; i++) {
				const e = files[i];
				var objImg = {
					"img": require("./assets/logs/" + e.split("./")[1])
				}
				imgList.push(objImg)
			}
			this.$store.commit('imgList',imgList)
		},
		methods: {

		}
	}
</script>
<style lang="less" scoped>
	/*每个页面公共css */
	@import '@/assets/reset.css';
</style>
