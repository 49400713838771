<template>
    <div class="product-introduction">
		<h1 class="modular-title">{{title}}</h1>
		<p class="product-introduction-p">{{textDetial}}</p>
		<div class="contact" @click="contactUs()">联系我们</div>
	</div>
  <!-- </div> -->
</template>
 
<script>
export default {
  props: ["title", "textDetial"],
  name:'ListHead',
  data() {
    return {};
  },
  methods: {
	contactUs() {
		this.$router.push('/ContactUs')
		let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
		var timer = setInterval(() => {
				document.documentElement.scrollTop = document.body.scrollTop = scrollTop -= 5000; //一次减50往上滑动
				if (scrollTop <= 0) {
					clearInterval(timer);
				}
			},
		10);
	}  
  }
};
</script>
 
<style lang = "less" scoped>
.product-introduction {
	padding: 118px 0 53px;
	background: url(http://romenstech.oss-cn-qingdao.aliyuncs.com/shop/88-100/singleimg/16685806615.png) no-repeat center center;
	background-size: cover;
	
	.modular-title {
	    font-size: 34px;
	    font-weight: 500;
	    color: #FFFFFF;
	    line-height: 34px;
	    margin-bottom: 45px;
	    text-align: center;
	}
	.product-introduction-p {
	    font-size: 24px;
	    font-weight: 400;
	    color: #FFFFFF;
	    line-height: 24px;
	    text-align: center;
		margin-bottom: 83px;
	}
	.contact {
		width: 200px;
		height: 48px;
		border: 2px solid #FFFFFF;
		border-radius: 5px;
		text-align: center;
		margin: 0 auto;
		font-size: 22px;
		font-weight: 400;
		color: #FFFFFF;
		line-height: 48px;
	}
}	

</style>