<template>
	<div class="product-center-m">
		<div class="product-center">
			<div class="product-menu">
				<div class="product-menu-item" v-for="(item,index) in boxList" :key="index"
					@click="choaseSwiper(index)">
					<p :class="swiperIndex==index?'active':''">{{item.title}}</p>
				</div>
			</div>
			<div class="product-banner">
				<div class="swiper-container" id="swiper12">
					<div class="swiper-wrapper">
						<div class="swiper-slide col" v-for="(item,index) in swiperList" :key="index"
							:class="activeSlide==index?'selected':''">
							<img :src="item.img" alt="">
							<h2>{{item.h2}}</h2>
							<p>{{item.p}}</p>
							<div @click="goPath(item.path)">了解详情</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import Swiper from "swiper";
	import "swiper/css/swiper.min.css";
	export default {
		name: "ProductSwiper",
		props: ["boxList"],
		data() {
			return {
				mySwiper: null,
				swiperIndex: 0,
				activeSlide: 0,
				swiperList: []
			}
		},
		mounted() {
			this.initSwiper()
			this.swiperList = this.boxList[0].list;
		},
		watch: {
			//slide数据发生变化时,更新swiper
			swiperList: { 
				deep: true,
				handler() {
					this.updateSwiper()
				}
			}
		},
		methods: {
			choaseSwiper(index) {
				this.swiperIndex = index;
				this.activeSlide = 0;
				this.swiperList = [] = this.boxList[index].list;
			},
			initSwiper() {
				var that = this;
				this.mySwiper = new Swiper('#swiper12', {
					direction: "horizontal",
					slidesPerView: "auto",
					centeredSlides: true,
					spaceBetween: 20,
					speed: 500,
					loop: true,
					autoplay: {
						dalay: 500,
						disableOnInteraction: false, //手动滑动之后不打断播放
					},
					observer: true,
					observeParents: true,
					on: {
						slideChange() {
							that.activeSlide = this.realIndex;
						}
					}
				})
			},
			//更新swiper
			updateSwiper() { 
				this.destroySwiper()
				this.$nextTick(() => {
					this.initSwiper()
				})
			},
			//销毁swiper
			destroySwiper() { 
				try {
					this.mySwiper.destroy(true, false)
				} catch (e) {
					console.log("删除轮播")
				}
			},
			goPath(path) {
				this.$router.push(path)
				let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
				var timer = setInterval(() => {
						document.documentElement.scrollTop = document.body.scrollTop = scrollTop -= 500; //一次减50往上滑动
						if (scrollTop <= 0) {
							clearInterval(timer);
						}
					},
				10);
			}
		}
	}
</script>
<style lang="less" scoped>
	.swiper-slide-next,
	.swiper-slide-prev {
		transform: scale(0.8) !important;
	}

	.swiper-slide-active {
		transform: scale(1) !important;
	}

	.product-center-m {
		width: 100%;
		background: #F6F9FE;

		.swiper-container {
			margin-top: 60px;
			width: 750px;
			padding: 10px 0;

			.swiper-wrapper {
				width: 362px;
				height: 550px;

				.col {
					margin: 0 auto;
					transform: scale(0.8);
				}

				.selected {
					transform: scale(1);
				}

				.swiper-slide {
					width: 100%;
					height: 100%;
					box-shadow: 0 0 10px #dddddd;
					border-radius: 4px;
					background: #ffffff;
					position: relative;
					transition: all 0.3s;

					img {
						display: block;
						width: 103px;
						height: 66px;
						padding-top: 43px;
						margin: 0 auto 30px;
					}

					h2 {
						font-size: 34px;
						font-weight: bold;
						color: #222932;
						line-height: 34px;
						text-align: center;
						margin-bottom: 37px;
					}

					p {
						margin: 0 auto;
						width: 272px;
						font-size: 24px;
						font-weight: 400;
						color: #777777;
						line-height: 34px;
						overflow: hidden;
						text-overflow: ellipsis;
						display: -webkit-box;
						-webkit-line-clamp: 7;
						-webkit-box-orient: vertical;
					}

					div {
						position: absolute;
						bottom: 30px;
						left: 50%;
						margin-left: -74px;
						width: 148px;
						height: 44px;
						background: #1482EE;
						border-radius: 6px;
						line-height: 44px;
						text-align: center;
						color: #ffffff;
						font-size: 24px;
					}
				}
			}
		}

		.product-menu {
			width: 100%;
			height: auto;
			border-bottom: 2px solid #DCDCDC;
			display: flex;
			justify-content: space-around;

			.product-menu-item {
				width: 100%;

				p {
					width: 100%;
					font-size: 30px;
					font-weight: bold;
					color: #222932;
					line-height: 30pxpx;
					text-align: center;
					line-height: 64px;
					border-bottom: 4px solid #F6F9FE;
				}

				p.active {
					color: #1482EE;
					border-bottom: 4px solid #1482EE;
				}
			}
		}
	}
</style>
