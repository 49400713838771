<template>
	<div class="footer">
		<div class="footer-top">
			<img class="footer-logo" src="https://romenstech.oss-cn-qingdao.aliyuncs.com/shop/88-100/singleimg/1664160424logo1@2x.png" alt="">
			<!-- <div class="footer-ewm">
				<h2>关注我们</h2>
				<div class="ewmBox ewmBox1" @click="showRwm(1)">
					<img :src="choaseNum==1?'http://romenstech.oss-cn-qingdao.aliyuncs.com/shop/88-100/singleimg/1669685130wx1.png':'http://romenstech.oss-cn-qingdao.aliyuncs.com/shop/88-100/singleimg/1669684986wx.png'" alt="">
					<div class="ewmImgBox" v-show="choaseNum==1" @click.stop="hideRwm()">
						<img src="https://romenstech.oss-cn-qingdao.aliyuncs.com/shop/88-100/singleimg/16636392261.jpg" alt="">
						<p>长按识别关注雨诺公众号</p>
					</div>
				</div>
				<div class="ewmBox ewmBox2" @click="showRwm(2)">
					<img :src="choaseNum==2?'http://romenstech.oss-cn-qingdao.aliyuncs.com/shop/88-100/singleimg/1669685145sp1.png':'http://romenstech.oss-cn-qingdao.aliyuncs.com/shop/88-100/singleimg/1669685021sp.png'" alt="">
					<div class="ewmImgBox" v-show="choaseNum==2" @click.stop="hideRwm()">
						<img src="https://romenstech.oss-cn-qingdao.aliyuncs.com/shop/88-100/singleimg/16636392392.png" alt="">
						<p>长按识别关注雨诺视频号</p>
					</div>
				</div>
			</div> -->
		</div>

		<div class="menu-box">
			<div class="menu-list" v-for="(item,index) in items" :key="index">
				<div class="menu-title" @click="seeMenu(index)">
					<p>{{item.name}}</p>
					<img :src="choaseIndex == index?'http://romenstech.oss-cn-qingdao.aliyuncs.com/shop/88-100/singleimg/1668654751xia-hei.png':'http://romenstech.oss-cn-qingdao.aliyuncs.com/shop/88-100/singleimg/1668654682xia-bai.png'">
				</div>
				<div class="menu-item-box" v-show="choaseIndex == index">
					<div class="menu-item" v-for="(items,index) in item.subItems" :key="index">
						<p @click="goPage(items.path)">{{items.name}}</p>
					</div>
				</div>
			</div>
			<div class="menu-bottom">
				<div>
					<span class="one">Copyright © 2002-2023  青岛雨诺网络信息股份有限公司</span>
					<span>版权所有</span>
				</div>
				<h2>
					<p><a target="_blank" href="https://beian.miit.gov.cn/#/Integrated/index">鲁ICP备10012854号-1</a></p>
					<img src="http://romenstech.oss-cn-qingdao.aliyuncs.com/shop/88-100/singleimg/1668480121jh.png">
					<p><a target="_blank" href="https://www.beian.gov.cn/portal/index.do">鲁公网安备 37020202000070号</a></p>
				</h2>
			</div>
		</div>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				choaseNum: null,
				choaseIndex: null,
				items: [
					{name: '解决方案',
					subItems: [
						{name: '集团企业解决方案',path: '/AppHomepage'},
						{name: '中大型企业解决方案',path: '/MediumSolotion'},
						{name: '小微企业解决方案',path: '/smallSolution'},
						{name: '医药新零售解决方案',path: '/groupSolution'}
					]},
					{name: '产品中心',
					subItems: [
						{name: '集团供应链云',path: '/ProductCenterGroup'},
						{name: 'OMS订单中台',path: '/ProductCenterOms'},
						{name: '连锁供应链云',path: '/ProductCenterChain'},
						{name: '私域商城',path: '/ProductCenterPrivate'},
						{name: '小微供应链云',path: '/ProductCenterMicroenterprise'},
						{name: '医馆云',path: '/ProductCenterHospital'},
						{name: '门店云',path: '/ProductCenterStore'},
						{name: '慢病管理',path: '/ProductCenterNcd'},
						{name: '医保云',path: '/ProductCenterInsurancel'},
						{name: 'DTP管理',path: '/ProductCenterDtp'},
						{name: 'WMS',path: '/ProductCenterWms'},
						{name: '处方平台',path: '/ProductCenterPrescription'},
						{name: '会员CRM云',path: '/ProductCenterCrm'}
					]},
					{name: '生态合作',
					subItems: [
						{name: '生态合作',path: '/EcologicalCooperation'}
					]},
					{name: '新闻中心',
					subItems: [
						{name: '新闻中心',path: '/newsCenter'}
					]},
					{name: '关于雨诺',
					subItems: [
						{name: '公司简介',path: '/AboutUs'},
						{name: '社会责任',path: '/SocialResponsibility'},
						{name: '联系我们',path: '/ContactUs'},
						{name: '人才招聘',path: '/TalentRecruitment'}
					]}
				],
			}
		},
		created() {
			// 页面路由发生变化，重置二维码和导航展开
			this.$router.beforeEach((to, from, next) => {
				this.choaseNum = null;
				this.choaseIndex = null;
				next()
			})
		},
		methods: {
			seeMenu(index) {
				if(this.choaseIndex == index) {
					this.choaseIndex = null;
				}else {
					this.choaseIndex = index;
				}
			},
			showRwm(type) {
				this.choaseNum = type;
			},
			hideRwm() {
				this.choaseNum = null;
			},
			goPage(src) {
				this.$router.push(src)
				let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
				var timer = setInterval(() => {
						document.documentElement.scrollTop = document.body.scrollTop = scrollTop -= 500; //一次减500往上滑动
						if (scrollTop <= 0) {
							clearInterval(timer);
						}
					},
				10);
			}
		},
	};
</script>
<style lang="less" scoped>
	.footer {
		width: 100vw;
		background: #1C2431;
		.footer-top {
			width: 100%;
			padding: 60px 0 40px;
			.footer-logo {
				display: block;
				width: 286px;
				height: 37px;
				margin: 0 auto 40px;
			}
			.footer-ewm {
				display: flex;
				justify-content: center;
				align-items: center;
				h2 {
					font-size: 40px;
					font-weight: bold;
					color: #FFFFFF;
					line-height: 40px;
				}
				.ewmBox {
					position: relative;
					.ewmImgBox {
						position: absolute;
						top: -360px;
						left: 50%;
						margin-left: -150px;
						width: 300px;
						height: 348px;
						background-size: cover !important;
						padding-top: 20px;
						z-index: 100;
						img {
							display: block;
							width: 280px;
							height: 270px;
							margin: 0 auto;
						}
						p {
							font-size: 16px;
							font-weight: 400;
							color: #222932;
							line-height: 28px;
							text-align: center;
						}
					}
				}
				.ewmBox1 {
					width: 56px;
					height: 46px;
					margin: 0 30px;
					.ewmImgBox {
						background: url(https://romenstech.oss-cn-qingdao.aliyuncs.com/shop/88-100/singleimg/16653933951.png) no-repeat center;
					}
					img {
						display: block;
						width: 56px;
						height: 46px;
					}
				}
				.ewmBox2 {
					width: 50px;
					height: 44px;
					.ewmImgBox {
						background: url(https://romenstech.oss-cn-qingdao.aliyuncs.com/shop/88-100/singleimg/16653933951.png) no-repeat center;
					}
					img {
						display: block;
						width: 50px;
						height: 44px;
					}
				}
			}
		}
		.menu-box {
			width: calc(100vw - 80px);
			margin: 0 auto;
			.menu-list {
				width: 100%;
				border-bottom: 1px solid #DCDCDC;
				.menu-title {
					width: 100%;
					height: 80px;

					display: flex;
					justify-content: space-between;
					align-items: center;
					p {
						font-size: 24px;
						font-weight: 500;
						color: #FFFFFF;
					}
					img {
						width: 12px;
						height: 11px;
					}
				}
				.menu-item-box {
					margin-bottom: 16px;
					display: flex;
					justify-content: flex-start;
					flex-wrap: wrap;
					.menu-item:nth-child(odd) {
						width: 40%;
					}
					.menu-item:nth-child(even) {
						width: 60%;
					}
					.menu-item p {
						font-size: 22px;
						font-weight: 400;
						color: #ADADAD;
						line-height: 44px;
					}
				}
			}
		}
		.menu-bottom {
			padding: 65px 0 95px;
			div {
				display: flex;
				justify-content: center;
				align-items: center;
				margin-bottom: 28px;
				span {
					height: 20px;
					font-size: 18px;
					font-weight: 400;
					color: #ADADAD;
					line-height: 20px;
				}
				.one {
					margin-right: 20px;
				}
			}
			h2 {
				display: flex;
				justify-content: center;
				align-items: center;
				p {
					font-size: 18px;
					font-weight: 400;
					color: #FFFFFF;
					line-height: 18px;
				}
				img {
					display: block;
					width: 32px;
					height: 33px;
					margin: 0 11px 0 27px;
				}
			}
		}
	}
</style>
