<template>
	<div class="digitization-box">
		<div class="digitization-item" v-for="item in boxList" :key="item.id" @click="goPath(item.path)">
			<div class="digitization-img" :style="'background: url('+ item.img +') no-repeat center center'"></div>
			<h2>{{item.title}}</h2>
			<div class="line-box">
				<div class="line"></div>
			</div>
			<p>{{item.textDetial}}</p>
		</div>
	</div>
</template>

<script>
	export default {
		props: ["boxList"],
		name: 'BigHealthyBox',
		data() {
			return {};
		},
		methods: {
			goPath(path) {
				this.$router.push(path)
				let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
				var timer = setInterval(() => {
						document.documentElement.scrollTop = document.body.scrollTop = scrollTop -= 500; //一次减50往上滑动
						if (scrollTop <= 0) {
							clearInterval(timer);
						}
					},
					10);
			}
		}
	};
</script>

<style lang="less" scoped>
	.digitization-box {
		width: 640px;
		margin: 0 auto;
		padding-bottom: 60px;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: flex-start;

		.digitization-item {
			width: 300px;
			padding-top: 60px;
			display: flex;
			flex-wrap: wrap;
			justify-content: center;


			.digitization-img {
				background-size: cover !important;
				display: block;
				width: 150px;
				height: 158px;
			}

			h2 {
				width: 100%;
				font-size: 30px;
				font-weight: bold;
				color: #222932;
				line-height: 30px;
				margin: 16px 0 22px 0;
				text-align: center;
			}

			.line-box {
				width: 100%;
				height: 2px;
				margin-bottom: 15px;

				.line {
					width: 67px;
					height: 2px;
					border-radius: 1px;
					background: #1482EE;
					margin: 0 auto;
				}
			}

			p {
				width: 297px;
				font-size: 24px;
				font-weight: 400;
				color: #777777;
				line-height: 34px;
				text-align: center;
			}
		}
	}
</style>
