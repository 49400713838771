import {
	createApp
} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'lib-flexible'
// import 'amfe-flexible'

createApp(App).use(store).use(router).mount('#app')
router.beforeEach((to, from, next) => {
	if (to.meta.title) { //拦截后设置标题
		document.title = to.meta.title
	}
	next()
})
