<template>
	<div class="contactBox" v-show="showPhone">
		<a href="tel:0532-85886309">
			<div class="phoneBox">
				<img src="http://romenstech.oss-cn-qingdao.aliyuncs.com/shop/88-100/singleimg/1669267456phone.gif">
			</div>
		</a>
	</div>
</template>

<script>
	export default {
		name: 'Home',
		data() {
			return {
				showPhone: false
			}
		},
		mounted: function() {
			window.addEventListener('scroll', this.handleScrolls) // 监听页面滚动
		},
		methods: {
			handleScrolls() {
				let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
				if (scrollTop > 0) {
					this.showPhone = true;
				} else {
					this.showPhone = false;
				}
			},
		}
	}
</script>

<style lang="less" scoped>
	.contactBox {
		position: fixed;
		right: 10px;
		bottom: 20%;
		z-index: 100;

		.phoneBox {
			width: 85px;
			height: 85px;
			background: #ffffff;
			box-shadow: 1px 1px 10px #ccc;
			border-radius: 50%;
			display: flex;
			justify-content: center;
			align-items: center;

			img {
				display: block;
				width: 60px;
				height: 60px;
			}
		}
	}
</style>
