<template>
	<div class="yunuo-header">
		<div class="menu-top"
			:style="isMenu?'background:#ffffff;box-shadow: none;':isScroll||isShowTop?'background:#ffffff;':'background:none;box-shadow: none;'">
			<img class="logo-open"
				:src="isMenu || isScroll||isShowTop?'http://romenstech.oss-cn-qingdao.aliyuncs.com/shop/88-100/singleimg/1668568895logo-lan.png':'http://romenstech.oss-cn-qingdao.aliyuncs.com/shop/88-100/singleimg/1668568929logo-bai.png'"
				@click="goHome()">
			<img :class="isMenu?'menu menu-close':'menu'"
				:src="isMenu? 'http://romenstech.oss-cn-qingdao.aliyuncs.com/shop/88-100/singleimg/1668654002close.png':isScroll||isShowTop?'http://romenstech.oss-cn-qingdao.aliyuncs.com/shop/88-100/singleimg/1668568088menu-lan.png':'http://romenstech.oss-cn-qingdao.aliyuncs.com/shop/88-100/singleimg/1668568128menu-bai.png'"
				@click="showMenu()">
		</div>
		<div :class="isMenu?'menu-box menu-box-active':'menu-box'">
			<div class="menu-left">
				<!-- 一级菜单 -->
				<div class="menu-one" v-for="(item,index) in items" :key="index"
					:class="index == menuOneIndex?'menu-one active':'menu-one'" @click="choasemenuOne(index,item)">
					<div class="line"></div>
					<p>{{item.name}}</p>
				</div>
			</div>
			<div class="menu-right">
				<!-- 二级菜单 -->
				<div class="menu-two" v-for="(item1,index1) in twoList" :key="index1">
					<div class="menu-two-title" @click="choasemenuTwo(index1,item1)">
						<h1>{{item1.name}}</h1>
						<img v-show="items[menuOneIndex].isSubshow"
							:src="item1.show?'http://romenstech.oss-cn-qingdao.aliyuncs.com/shop/88-100/singleimg/1668576768more-lan.png':'http://romenstech.oss-cn-qingdao.aliyuncs.com/shop/88-100/singleimg/1669168985more-bai.png'">
					</div>
					<!-- 三级菜单 -->
					<div class="menu-three" v-for="(item2,index2) in item1.liItems" :key="index2" v-show="item1.show">
						<div class="menu-three-item" @click="choasemenuThree(item2,)">{{item2.name}}</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				isShowTop: false,
				isMenu: false,
				isScroll: false,
				menuOneIndex: 0,
				menuTwoIndex: 0,
				twoList: [],
				items: [{
						id: 1,
						name: "解决方案",
						isSubshow: false,
						subItems: [{
								path: "/EnterpriseSolution",
								name: "集团企业解决方案",
							},
							{
								path: "/MediumSolotion",
								name: "中大型企业解决方案",
							},
							{
								path: '/smallSolution',
								name: "小微企业解决方案",
							},
							{
								path: "/groupSolution",
								name: "医药新零售解决方案",
							},

						],
					},
					{
						id: 2,
						name: "产品中心",
						isSubshow: true,
						subItems: [{
								name: "企业管理类",
								show: false,
								liItems: [{
										path: "/ProductCenterGroup",
										name: "集团供应链云",
									},
									{
										path: "/ProductCenterChain",
										name: "连锁供应链云",
									},
									{
										path: "/ProductCenterMicroenterprise",
										name: "小微供应链云",
									},
									{
										path: "/ProductCenterStore",
										name: "门店云",
									},
									{
										path: "/ProductCenterInsurancel",
										name: "医保云",
									},
									{
										path: "/ProductCenterWms",
										name: "WMS",
									},
								],
							},
							{
								name: "新零售类",
								show: false,
								liItems: [{
										path: "/ProductCenterCrm",
										name: "会员CRM云",
									},
									{
										path: "/ProductCenterPrivate",
										name: "私域商城",
									},
									{
										path: "/ProductCenterOms",
										name: "OMS订单中台",
									}
								],
							},
							{
								name: "医药健康类",
								show: false,
								liItems: [{
										path: "/ProductCenterHospital",
										name: "医馆云",
									},
									{
										path: "/ProductCenterDtp",
										name: "DTP管理",
									},
									{
										path: "/ProductCenterNcd",
										name: "慢病管理",
									},
									{
										path: "/ProductCenterPrescription",
										name: "处方平台",
									}
								],
							},
						],
					},
					{
						id: 3,
						name: "生态合作",
						path: "/EcologicalCooperation",
						isSubshow: false,
					},
					{
						id: 4,
						name: "新闻中心",
						path: "/newsCenter",
						isSubshow: false,
					},
					{
						id: 5,
						name: "关于雨诺",
						isSubshow: false,
						subItems: [{
								name: "公司简介",
								path: "/AboutUs",
							},
							{
								name: "社会责任",
								path: '/SocialResponsibility'
							},
							{
								name: "联系我们",
								path: "/ContactUs"
							},
							{
								name: "人才招聘",
								path: '/TalentRecruitment'
							},
						],
					},
				]
			};
		},
		
		created() {
			this.$router.beforeEach((to, from, next) => {
				// 页面导航变化刷新数据
				this.menuOneIndex = 0;
				this.menuTwoIndex = 0;
				console.log(this.items)
				this.twoList = this.items[0].subItems;
				console.log(this.twoList)
				// 页面导航数据变化设置顶部导航状态（目前仅对新闻中心和新闻详情页生效）
				var str = to.name;
				console.log(str,'str')
				var isShowTop = str.indexOf('NewsInfor') != -1 || str.indexOf('NewsCenter') != -1;
				// console.log(isShowTop,'isShowTop00')
				this.isShowTop = isShowTop
				next()
			})
		},
		watch: {
			$route: {
				handler: function(route) {
					var isShowTop = route.path.indexOf('NewsInfor') != -1 || route.path.indexOf('NewsCenter') != -1 || route.path.indexOf('newsCenter') != -1;
					console.log(isShowTop,'isShowTop')
					if(isShowTop){
						this.isShowTop = true
					}
					console.log(this.isShowTop,'isShowTop')
				},
				immediate: true
			}
		},
		mounted: function() {
			console.log(this.items)
			this.twoList = this.items[0].subItems;
			console.log(this.twoList)
			window.addEventListener('scroll', this.handleScrolls) // 监听页面滚动
		},
		methods: {
			choasemenuOne(index, item) {
				if (item.path) {
					this.twoList = [];
					this.goPage(item.path);
				} else {
					console.log(this.items[index].subItems,'this.items[index].subItems')
					this.menuOneIndex = index;
					this.twoList = this.items[index].subItems;
				}
			},
			choasemenuTwo(index, item) {
				if (item.path) {
					this.goPage(item.path);
				} else {
					item.show = !item.show;
				}
				this.menuTwoIndex = index;

			},
			choasemenuThree(item) {
				if (item.path) {
					this.goPage(item.path);
				}
			},
			showMenu() {
				this.isMenu = !this.isMenu;
			},
			goHome() {
				this.goPage('/Home')
			},
			handleScrolls() {
				let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
				if (scrollTop > 0) {
					this.isScroll = true;
				} else {
					this.isScroll = false;
				}
			},
			goPage(src) {
				if (src) {
					this.isMenu = false;
					this.$router.push(src);
					let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
					var timer = setInterval(() => {
							document.documentElement.scrollTop = document.body.scrollTop = scrollTop -=
								500; //一次减50往上滑动
							if (scrollTop <= 0) {
								clearInterval(timer);
							}
						},
						10);
				}
			}
		}
	};
</script>
<style lang="less" scoped>
	.yunuo-header {
		width: 100vw;
		background: none;
		position: fixed;
		top: -1px;
		left: 0;
		z-index: 1000;

		.menu-box {
			width: 100vw;
			height: 0;
			overflow: hidden;
		}

		.menu-top {
			width: calc(100vw - 80px);
			padding: 0 40px;
			height: 106px;
			margin: 0 auto;
			display: flex;
			justify-content: space-between;
			align-items: center;
			box-shadow: 0 1px 20px rgb(54 54 54 / 40%);

			.logo-open {
				display: block;
				width: 268px;
				height: 36px;
			}

			.menu {
				display: block;
				width: 44px;
				height: 36px;
			}

			.menu-close {
				width: 36px !important;
				height: 36px !important;
			}
		}

		.menu-box-active {
			width: 100vw;
			height: calc(100vh - 106px);
			background: #ffffff;
			overflow: hidden;

			.menu-left {
				width: 258px;
				height: calc(100vh - 106px);
				background: #F6F9FE;
				float: left;

				.menu-one {
					width: 100%;
					height: 90px;
					background: #F6F9FE;
					display: flex;
					justify-content: flex-start;
					align-items: center;

					.line {
						width: 4px;
						height: 61px;
						background: #F6F9FE;
						margin-left: 40px;
						margin-right: 30px;
					}

					p {
						font-size: 28px;
						font-weight: 400;
						color: #222932;
					}
				}

				.active {
					background: #FFFFFF;

					.line {
						background: #1482EE;
					}

					p {
						color: #1482EE;
					}
				}
			}

			.menu-right {
				width: 448px;
				padding-left: 44px;
				height: calc(100vh - 106px);
				background: #ffffff;
				float: left;

				.menu-two {
					border-bottom: 2px solid #DCDCDC;

					.menu-two-title {
						width: 100%;
						height: 108px;
						display: flex;
						justify-content: space-between;
						align-items: center;

						h1 {
							font-size: 26px;
							font-weight: 400;
							color: #222932;
						}

						img {
							display: block;
							width: 34px;
							height: 16px;
							margin-right: 44px;
						}
					}

					.menu-three {

						&:last-child {
							padding-bottom: 40px;
						}

						.menu-three-item {
							height: 62px;
							padding-left: 30px;
							font-size: 24px;
							font-weight: 400;
							color: #777777;
							line-height: 62px;
						}
					}
				}
			}
		}
	}
</style>
