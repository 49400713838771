<template>
	<div>
		<div class="swiper-container" id="swiper1">
			<div class="swiper-wrapper">
				<div class="swiper-slide" v-for="(item,index) in boxList" :key="index" :data-path="item.path"
					:style="'background: url('+ item.img +') no-repeat top center;'">
					<div class="banner-m" :data-path="item.path">
						<h2 :data-path="item.path">
							{{item.title}}
						</h2>
						<h2 :data-path="item.path">
							{{item.title1}}
						</h2>
						<p :data-path="item.path" :style="item.type == 2?'text-align: center;':''">{{item.infor}}</p>
						<p class="marTop" :data-path="item.path" v-if="item.type == 2" :style="item.type == 2?'text-align: center;':''">{{item.infor1}}</p>
					</div>
				</div>
			</div>
			<!-- 如果需要导航按钮 -->
			<div class="swiper-pagination"></div>
		</div>
	</div>
</template>
<script>
	import Swiper from "swiper";
	import "swiper/css/swiper.min.css";
	export default {
		name: "Smallswiper",
		props: ["boxList"],
		mounted() {
			this.initSwiper()
		},
		methods: {
			initSwiper() {
				var that = this;
				new Swiper('#swiper1', {
					// effect: 'fade',
                    spaceBetween: 0,
					loop: true, // 循环模式选项
					speed: 500,
					coverflowEffect: {
						rotate: 0,
						stretch: 0,
						depth: 40,
						modifier: 1,
						slideShadows: false,
                    },
					autoplay: { // 自动滑动
						delay: 3000, //1秒切换一次
						disableOnInteraction: false
					},
					pagination: { // 如果需要分页器
						el: '.swiper-pagination',
						clickable: true,
					},
					pauseOnMouseEnter: true,
					preventLinksPropagation: false,
					on: {
						click: function(e) {
							if (e.target.dataset.path != '') {
								that.$router.push(e.target.dataset.path)
							}
						}
					}
				})
			},
		}
	}
</script>
<style lang="less" scoped>
	.swiper-container {
		width: 100vw;
		height: 1000px;

		.swiper-slide {
			width: 100vw;
			height: 1000px;
			background-size: cover !important;
			display: flex;
			justify-content: center;
			align-items: center;

			.banner-m {
				width: 100%;

				h2 {
					font-size: 120px;
					font-weight: 800;
					color: #FFFFFF;
					line-height: 120px;
					text-align: center;

					&:nth-child(1) {
						margin-bottom: 30px;
					}
				}

				p {
					font-size: 40px;
					font-weight: 500;
					color: #FFFFFF;
					line-height: 40px;
					text-align: center;
					margin-top: 74px;
				}
				.marTop {
					margin-top: 14px;
				}
			}
		}
	}
</style>
