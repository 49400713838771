<template>
	<div>
		<Header></Header>
		<div class="home">
			<Banner :boxList="srcList"></Banner>
			<ListHead :textDetial="modularTitle" title="打造医药大健康产业数字化能力引擎" :did="3" bigWidth="yes"></ListHead>
			<BigHealthyBox :boxList="BigHealthyTxt"></BigHealthyBox>
			<div class="product-m">
				<ListHead title="产品中心" :did="4"></ListHead>
				<ProductSwiper :boxList="ProductSwiperTxt"></ProductSwiper>
			</div>
			<div class="company-profile">
				<h2>公司介绍</h2>
				<p style="margin-bottom: 20px">
					青岛雨诺网络信息股份有限公司（证券代码：837579）成立于2002年，是医药大健康行业数字化服务提供商，公司总部位于山东青岛，全国各地设立了15余家分子公司及办事处，公司以“持续推动医药大健康产业数字经济转型”为使命，致力于成为“更值得信赖的企业数字化服务商”。
				</p>
				<p>从信息化到数字化，从医药零售到医药大健康，雨诺专注行业20余年，秉承产品即服务的理念，致力于为客户实现数字化转型，涵盖医药企业管理、医药新零售、医药健康管理三大产品体系以及集团企业、中大型企业、小微企业、新零售等解决方案，在专业、深入的基础上，能够以不同版本适应不同规模、不同业态的企业数字化需求，在全国范围内，累计赢得了60余家百强用户，10万多家药店的信赖与合作，并且雨诺在持续创新探索的基础上，聚合上下游资源的力量，以真诚陪伴客户成长，以技术推动产业变革。
				</p>
				<div>
					<div class="video-bg">
						<img src="https://romenstech.oss-cn-qingdao.aliyuncs.com/shop/88-100/singleimg/1663121662video.png"
							@click="playVide()">
					</div>
				</div>

				<!-- <div class="profile-video">
					<video ref="myVideo" :poster="poster" :src="src" controls="controls"></video>
					<img src="https://www.inspur.com/lcjtww/uiFramework/commonResource/image/2021012115310018030.png" alt=""
						@click="operateVideo" class="pointer operate-btn" :class="{ 'fade-out': videoState }" />
				</div> -->
			</div>
			<Video v-if="isFlag" :poster="poster" :src="src" :controls="controls" :isFlag="isFlag" @sdjf="dfsf" />
			<ListHead title="新闻资讯" :did="6"></ListHead>
			<HomeNewsBox :boxList="newsList"></HomeNewsBox>
			<div class="moreNews" @click="goNewsCenter()">
				<span>更多新闻</span>
				<span>></span>
			</div>
			<div class="cunstom-m">
				<ListHead title="合作客户" :did="5" backgrounds="nowhite"></ListHead>
				<HomeBottomSweiper :boxList="HomeBottomSweiperTxt"></HomeBottomSweiper>
			</div>
			<ContactUs title="持续推动医药大健康产业数字经济转型" textDetial="我们能做得更多更好，更多需求请联系我们！"></ContactUs>
		</div>
		<!-- <Footer></Footer> -->
		<ContactBox></ContactBox>
	</div>
</template>
<script>
import Header from '@/components/common/Header.vue';
// import Footer from '@/components/common/Footer.vue';
import Banner from "../components/common/Banner.vue";
import ListHead from "../components/common/ListHead.vue";
import BigHealthyBox from "../components/common/BigHealthyBox.vue";
import ProductSwiper from "../components/common/ProductSwiper.vue";
import HomeBottomSweiper from "../components/common/HomeBottomSweiper.vue";
import ContactUs from "../components/common/ContactUs.vue";
import Video from "../components/common/Video.vue";
import HomeNewsBox from "../components/common/HomeNewsBox.vue";
import ContactBox from "../components/common/ContactBox.vue";
export default {
	data() {
		return {
			videoState: false,
			auto: true,
			poster: "https://romenstech.oss-cn-qingdao.aliyuncs.com/shop/88-100/singleimg/16661701123.png",
			src: "http://v.yy365.cn/romens_video_2024.mp4",
			controls: true,
			isFlag: false,
			newsList: {
				img: 'http://118.190.6.79:9000/display/gwpt2/luyan2.png',
				time: '2024.07.11',
				title: '鹭燕医药集团牵手雨诺启动医药零售数字化项目',
				path: '/NewsInfor53',
				list: [
					{
						title: '内蒙古北域携手雨诺成功搭建数字化平台',
						time: '01-22',
						path: '/NewsInfor60'
					}, {
						title: '广东多家“双通道”药店牵手雨诺跑通电子处方流转医保结算',
						time: '01-10',
						path: '/NewsInfor59'
					},
					{
						title: '笃行致远 惟实励新 | 2024年终总结',
						time: '12-31',
						path: '/NewsInfor58'
					},
					{
						title: '恩施元昌医药携手雨诺打造数智电商WMS系统',
						time: '11-07',
						path: '/NewsInfor57'
					},
					{
						title: '重药集团携手雨诺启动C端会员营销平台项目',
						time: '09-13',
						path: '/NewsInfor56'
					},
					{
						title: '南同世家牵手雨诺启动集团化一体化项目',
						time: '08-08',
						path: '/NewsInfor55'
					},
					{
						title: '2024年西普会参会指南',
						time: '07-31',
						path: '/NewsInfor54'
					},
					{
						title: '亚虹医药牵手雨诺股份建成全员专属 AI 助理',
						time: '06-28',
						path: '/NewsInfor52'
					},
					{
						title: '一体化全面赋能丨海南两大连锁企业牵手雨诺',
						time: '06-26',
						path: '/NewsInfor51'
					},
					{
						title: '用雨诺协同服务平台，多维度提升上下游协同能力',
						time: '05-27',
						path: '/NewsInfor50'
					},
					// {
					// 	title: '国风金百合携手雨诺打造数智化WMS系统',
					// 	time: '05-08',
					// 	path: '/NewsInfor48'
					// },
					// {
					// 	title: '连锁药店如何与各大电商平台对齐O2O颗粒度',
					// 	time: '04-09',
					// 	path: '/NewsInfor47'
					// },
					// {
					// 	title: '2024年西鼎会参会指南',
					// 	time: '03-15',
					// 	path: '/NewsInfor46'
					// },
					// {
					// 	title: '发展混沌期，区域规模连锁如何健康成长，成就热辣滚烫？',
					// 	time: '03-15',
					// 	path: '/NewsInfor43'
					// },
				]
			},
			srcList: [{
				type: 2,
				img: "https://romenstech.oss-cn-qingdao.aliyuncs.com/shop/88-100/singleimg/16685804121.png",
				title: '数智重构',
				title1: '创变未来',
				infor: '数字时代',
				infor1: '以技术驱动医药大健康产业升级',
				path: ''
			},

			{
				type: 1,
				img: "https://romenstech.oss-cn-qingdao.aliyuncs.com/shop/88-100/singleimg/16685804642.png",
				title: '雨诺云V3.0',
				infor: '重构医药大健康数字化能力',
				path: ''
			},

			],
			HomeBottomSweiperTxt: {
				// list1: [{
				// 	img: 'https://romenstech.oss-cn-qingdao.aliyuncs.com/shop/88-100/singleimg/169344812901.png'
				// },
				// {
				// 	img: 'https://romenstech.oss-cn-qingdao.aliyuncs.com/shop/88-100/singleimg/169344814202.png'
				// },
				// {
				// 	img: 'https://romenstech.oss-cn-qingdao.aliyuncs.com/shop/88-100/singleimg/169344815203.png'
				// },
				// {
				// 	img: 'https://romenstech.oss-cn-qingdao.aliyuncs.com/shop/88-100/singleimg/169344816804.png'
				// },
				// {
				// 	img: 'https://romenstech.oss-cn-qingdao.aliyuncs.com/shop/88-100/singleimg/169344818305.png'
				// },
				// {
				// 	img: 'https://romenstech.oss-cn-qingdao.aliyuncs.com/shop/88-100/singleimg/169344819806.png'
				// },
				// {
				// 	img: 'https://romenstech.oss-cn-qingdao.aliyuncs.com/shop/88-100/singleimg/169344821307.png'
				// },
				// {
				// 	img: 'https://romenstech.oss-cn-qingdao.aliyuncs.com/shop/88-100/singleimg/169344822508.png'
				// },
				// {
				// 	img: 'https://romenstech.oss-cn-qingdao.aliyuncs.com/shop/88-100/singleimg/169344824509.png'
				// },
				// {
				// 	img: 'https://romenstech.oss-cn-qingdao.aliyuncs.com/shop/88-100/singleimg/169344826410.png'
				// },
				// {
				// 	img: 'https://romenstech.oss-cn-qingdao.aliyuncs.com/shop/88-100/singleimg/169344828011.png'
				// },
				// {
				// 	img: 'https://romenstech.oss-cn-qingdao.aliyuncs.com/shop/88-100/singleimg/169344829512.png'
				// },
				// {
				// 	img: 'https://romenstech.oss-cn-qingdao.aliyuncs.com/shop/88-100/singleimg/169344830913.png'
				// },
				// {
				// 	img: 'https://romenstech.oss-cn-qingdao.aliyuncs.com/shop/88-100/singleimg/169344832314.png'
				// },
				// {
				// 	img: 'https://romenstech.oss-cn-qingdao.aliyuncs.com/shop/88-100/singleimg/169344833915.png'
				// },
				// {
				// 	img: 'https://romenstech.oss-cn-qingdao.aliyuncs.com/shop/88-100/singleimg/169344835616.png'
				// },
				// {
				// 	img: 'https://romenstech.oss-cn-qingdao.aliyuncs.com/shop/88-100/singleimg/169344837117.png'
				// },
				// {
				// 	img: 'https://romenstech.oss-cn-qingdao.aliyuncs.com/shop/88-100/singleimg/169344838918.png'
				// },
				// {
				// 	img: 'https://romenstech.oss-cn-qingdao.aliyuncs.com/shop/88-100/singleimg/169344840419.png'
				// },
				// {
				// 	img: 'https://romenstech.oss-cn-qingdao.aliyuncs.com/shop/88-100/singleimg/169344841920.png'
				// },
				// {
				// 	img: 'https://romenstech.oss-cn-qingdao.aliyuncs.com/shop/88-100/singleimg/169344843321.png'
				// }
				// ],
				// list2: [{
				// 	img: 'https://romenstech.oss-cn-qingdao.aliyuncs.com/shop/88-100/singleimg/169344845222.png'
				// },
				// {
				// 	img: 'https://romenstech.oss-cn-qingdao.aliyuncs.com/shop/88-100/singleimg/169344847023.png'
				// },
				// {
				// 	img: 'https://romenstech.oss-cn-qingdao.aliyuncs.com/shop/88-100/singleimg/169344848424.png'
				// },
				// {
				// 	img: 'https://romenstech.oss-cn-qingdao.aliyuncs.com/shop/88-100/singleimg/169344849925.png'
				// },
				// {
				// 	img: 'https://romenstech.oss-cn-qingdao.aliyuncs.com/shop/88-100/singleimg/169344852226.png'
				// },
				// {
				// 	img: 'https://romenstech.oss-cn-qingdao.aliyuncs.com/shop/88-100/singleimg/169344853927.png'
				// },
				// {
				// 	img: 'https://romenstech.oss-cn-qingdao.aliyuncs.com/shop/88-100/singleimg/169344855628.png'
				// },
				// {
				// 	img: 'https://romenstech.oss-cn-qingdao.aliyuncs.com/shop/88-100/singleimg/169344857229.png'
				// },
				// {
				// 	img: 'https://romenstech.oss-cn-qingdao.aliyuncs.com/shop/88-100/singleimg/169344859230.png'
				// },
				// {
				// 	img: 'https://romenstech.oss-cn-qingdao.aliyuncs.com/shop/88-100/singleimg/169344861631.png'
				// },
				// {
				// 	img: 'https://romenstech.oss-cn-qingdao.aliyuncs.com/shop/88-100/singleimg/169344863132.png'
				// },
				// {
				// 	img: 'https://romenstech.oss-cn-qingdao.aliyuncs.com/shop/88-100/singleimg/169344864833.png'
				// },
				// {
				// 	img: 'https://romenstech.oss-cn-qingdao.aliyuncs.com/shop/88-100/singleimg/169344866434.png'
				// },
				// {
				// 	img: 'https://romenstech.oss-cn-qingdao.aliyuncs.com/shop/88-100/singleimg/169344868235.png'
				// },
				// {
				// 	img: 'https://romenstech.oss-cn-qingdao.aliyuncs.com/shop/88-100/singleimg/169344871536.png'
				// },
				// {
				// 	img: 'https://romenstech.oss-cn-qingdao.aliyuncs.com/shop/88-100/singleimg/169344875137.png'
				// },
				// {
				// 	img: 'https://romenstech.oss-cn-qingdao.aliyuncs.com/shop/88-100/singleimg/169344876838.png'
				// },
				// {
				// 	img: 'https://romenstech.oss-cn-qingdao.aliyuncs.com/shop/88-100/singleimg/169344879339.png'
				// },
				// {
				// 	img: 'https://romenstech.oss-cn-qingdao.aliyuncs.com/shop/88-100/singleimg/169344886040.png'
				// },
				// {
				// 	img: 'https://romenstech.oss-cn-qingdao.aliyuncs.com/shop/88-100/singleimg/169344888241.png'
				// },
				// {
				// 	img: 'https://romenstech.oss-cn-qingdao.aliyuncs.com/shop/88-100/singleimg/169344890642.png'
				// }
				// ],
				// list3: [{
				// 	img: 'https://romenstech.oss-cn-qingdao.aliyuncs.com/shop/88-100/singleimg/169344894143.png'
				// },
				// {
				// 	img: 'https://romenstech.oss-cn-qingdao.aliyuncs.com/shop/88-100/singleimg/169344897644.png'
				// },
				// {
				// 	img: 'https://romenstech.oss-cn-qingdao.aliyuncs.com/shop/88-100/singleimg/169344899245.png'
				// },
				// {
				// 	img: 'https://romenstech.oss-cn-qingdao.aliyuncs.com/shop/88-100/singleimg/169344901346.png'
				// },
				// {
				// 	img: 'https://romenstech.oss-cn-qingdao.aliyuncs.com/shop/88-100/singleimg/169344903647.png'
				// },
				// {
				// 	img: 'https://romenstech.oss-cn-qingdao.aliyuncs.com/shop/88-100/singleimg/169344905048.png'
				// },
				// {
				// 	img: 'https://romenstech.oss-cn-qingdao.aliyuncs.com/shop/88-100/singleimg/169344906649.png'
				// },
				// {
				// 	img: 'https://romenstech.oss-cn-qingdao.aliyuncs.com/shop/88-100/singleimg/169344908350.png'
				// },
				// {
				// 	img: 'https://romenstech.oss-cn-qingdao.aliyuncs.com/shop/88-100/singleimg/169344910451.png'
				// },
				// {
				// 	img: 'https://romenstech.oss-cn-qingdao.aliyuncs.com/shop/88-100/singleimg/169344912052.png'
				// },
				// {
				// 	img: 'https://romenstech.oss-cn-qingdao.aliyuncs.com/shop/88-100/singleimg/169344913453.png'
				// },
				// {
				// 	img: 'https://romenstech.oss-cn-qingdao.aliyuncs.com/shop/88-100/singleimg/169344915254.png'
				// },
				// {
				// 	img: 'https://romenstech.oss-cn-qingdao.aliyuncs.com/shop/88-100/singleimg/169344917155.png'
				// },
				// {
				// 	img: 'https://romenstech.oss-cn-qingdao.aliyuncs.com/shop/88-100/singleimg/169344919356.png'
				// },
				// {
				// 	img: 'https://romenstech.oss-cn-qingdao.aliyuncs.com/shop/88-100/singleimg/169344921357.png'
				// },
				// {
				// 	img: 'https://romenstech.oss-cn-qingdao.aliyuncs.com/shop/88-100/singleimg/169344923158.png'
				// },
				// {
				// 	img: 'https://romenstech.oss-cn-qingdao.aliyuncs.com/shop/88-100/singleimg/169344924559.png'
				// },
				// {
				// 	img: 'https://romenstech.oss-cn-qingdao.aliyuncs.com/shop/88-100/singleimg/169344925860.png'
				// }
				// ]
				list1:[],
				list2:[],
				list3:[]
			},
			ProductSwiperTxt: [{
				title: '企业管理类',
				list: [{
					img: 'https://romenstech.oss-cn-qingdao.aliyuncs.com/shop/88-100/singleimg/16630607101.png',
					h2: '集团供应链云',
					p: '基于中台架构思想，使用云原生技术，把传统的采购、销售、库存、财务等转化为整个企业系统运行的核心，为企业数字化转型提供解决方案。',
					path: '/ProductCenterGroup'
				},
				{
					img: 'https://romenstech.oss-cn-qingdao.aliyuncs.com/shop/88-100/singleimg/16630607312.png',
					h2: '连锁供应链云',
					p: '基于强大的中台能力，为企业构建有效管控、高效协同的供应链体系，采购管理、库存管理、配送管理、业务系统、财务系统等多系统深度集成，提升整体效能，助力高成长型企业加速数字化转型进程。',
					path: '/ProductCenterChain'
				},
				{
					img: 'https://romenstech.oss-cn-qingdao.aliyuncs.com/shop/88-100/singleimg/16630607493.png',
					h2: '小微供应链云',
					p: '以数字化能力，赋能单体药店及小微连锁药店创收增效，集操作便捷、智能分析、安全无忧、异地协同等优点于一身，轻松搞定药店经营管理的全部所需，有效支撑成长型药店向数字化转型的管理诉求。',
					path: '/ProductCenterMicroenterprise'
				},
				{
					img: 'https://romenstech.oss-cn-qingdao.aliyuncs.com/shop/88-100/singleimg/16630607664.png',
					h2: '门店云',
					p: '医药行业连锁门店管理平台，严格按照 GSP 要求满足门店进销存业务、为门店提供 POS 零售、请货配送、收货验收入库业务、库存管理和会员营销等功能，为企业提供一站式服务。',
					path: '/ProductCenterStore'
				},
				{
					img: 'https://romenstech.oss-cn-qingdao.aliyuncs.com/shop/88-100/singleimg/16630607825.png',
					h2: '医保云',
					p: '帮助企业构建高效便捷的医保管理平台，按照规范与医保系统对接，支持多省份医保系统，实现了POS收款与医保系统无缝衔接，同时支持身份证、电子医保凭证、实体医保卡读卡等多种医保支付方式。',
					path: '/ProductCenterInsurancel'
				},
				{
					img: 'https://romenstech.oss-cn-qingdao.aliyuncs.com/shop/88-100/singleimg/16630607956.png',
					h2: 'WMS',
					p: '新一代的仓储物流管理系统，投入少、差错小、效率高、上线快是其最显著的优势。雨诺WMS能够极大的提升企业仓储信息化水平和作业效率，解决医药企业库房人员成本高、作业效率低下、库房管理难等痛点。',
					path: '/ProductCenterWms'
				}
				]
			},
			{
				title: '新零售类',
				list: [{
					img: 'https://romenstech.oss-cn-qingdao.aliyuncs.com/shop/88-100/singleimg/16630609607.png',
					h2: '会员CRM云',
					p: '基于中台架构，以完备的组织管理中心、会员中心、内容中心、券中心做支撑，深度打通各系统，助力医药连锁构建以专业化服务为基础的顾客信任体系。',
					path: '/ProductCenterCrm'
				},
				{
					img: 'https://romenstech.oss-cn-qingdao.aliyuncs.com/shop/88-100/singleimg/16630609778.png',
					h2: 'OMS订单中台',
					p: '整合平台、系统、账单、数据等，使原本分散在各个线上平台的业务得到集中处理；自动解析多平台规则，简化店员工作流程；无缝对接多个信息化系统，减轻门店、运营以及财务等部门的压力；解决连锁药店各电商平台订单分散、处理效率低等问题。',
					path: '/ProductCenterOms'
				},
				{
					img: 'https://romenstech.oss-cn-qingdao.aliyuncs.com/shop/88-100/singleimg/16630610229.png',
					h2: '私域商城',
					p: '帮助企业打通线上销售通路，搭建连锁O2O+B2C+BBC的私域流量系统，满足线上门店与线下门店一致的价格管理、特殊品单独定价管理、库存类实时管理、各种线上促销场景需求，与云ERP、互联网医院、会员中心、库存中心、券中心深度集成。',
					path: '/ProductCenterPrivate'
				}
				]
			},
			{
				title: '医药健康类',
				list: [{
					img: 'https://romenstech.oss-cn-qingdao.aliyuncs.com/shop/88-100/singleimg/166306123910.png',
					h2: '医馆云',
					p: '一站式满足企业对会员管理、排班管理、预约挂号、医师坐诊、收费管理、药房管理等流程的集中管理，为不同的门店定制独有的业务流程，提高业务效率和关键数据安全性，为药店“诊疗+用药”的新增长点进行技术支撑。',
					path: '/ProductCenterHospital'
				},
				{
					img: 'https://romenstech.oss-cn-qingdao.aliyuncs.com/shop/88-100/singleimg/166306125711.png',
					h2: '处方平台',
					p: '雨诺处方平台将互联网医院、远程审方服务平台、药店处方药销售纳入一体化管理，以专业的药学服务让顾客购药更方便更安全，缓解执业药师缺口大的现状，快速适应“互联网+医疗”政策和处方外流政策，实现医疗服务与药品销售相融合，企业发展空间进一步拓展，从而打造从医到药的服务闭环。',
					path: '/ProductCenterPrescription'
				},
				{
					img: 'https://romenstech.oss-cn-qingdao.aliyuncs.com/shop/88-100/singleimg/166306127212.png',
					h2: '慢病管理系统',
					p: '在大数据背景下建立的现代化健康管理平台，“终端+数据+服务”三位一体为医药连锁提供智能的慢病管理服务，帮助药房为患者提供更好的个性化健康服务和专业化用药指导，提高患者对药房的信任度。',
					path: '/ProductCenterNcd'
				},
				{
					img: 'https://romenstech.oss-cn-qingdao.aliyuncs.com/shop/88-100/singleimg/166306128513.png',
					h2: 'DTP管理系统',
					p: '雨诺DTP管理系统帮助客户在院外搭建一个专业化、智能化、以患者为中心的药事服务和健康管理平台。平台为患者提供患者建档、处方管理、药历记录、用药指导、用药提醒、用药回访、知识库、药品暂存、配送、冷链管理等服务，以满足患者的需求，提升忠诚度和认可度，同时可以帮助药房管理人员进行面向患者的全周期信息管理。',
					path: '/ProductCenterDtp'
				}
				]
			},
			],
			modularTitle: '连接企业发展及转型所需资源，满足海量、实时、多场景的顾客需求，协助企业数字化转型落地',
			BigHealthyTxt: [{
				img: 'https://romenstech.oss-cn-qingdao.aliyuncs.com/shop/88-100/singleimg/1663053029jituan.png',
				title: '集团企业解决方案',
				textDetial: '打造集团化、平台化、一体化、中台化的数字能力引擎',
				id: 1,
				path: '/EnterpriseSolution'
			},
			{
				img: 'https://romenstech.oss-cn-qingdao.aliyuncs.com/shop//singleimg/16660819051.png',
				title: '中大型企业解决方案',
				textDetial: '打造平台化、一体化、中台化、全渠道化的数字能力引擎',
				id: 4,
				path: '/MediumSolotion'
			},
			{
				img: 'https://romenstech.oss-cn-qingdao.aliyuncs.com/shop/88-100/singleimg/1663053107xiaowei.png',
				title: '小微企业解决方案',
				textDetial: '以数字化赋能单体药店及小微连锁药店创收增效',
				id: 3,
				path: '/smallSolution'
			},
			{
				img: 'https://romenstech.oss-cn-qingdao.aliyuncs.com/shop/88-100/singleimg/1663053095xinlingshou.png',
				title: '医药新零售解决方案',
				textDetial: '帮助医药企业构建“医+药+服务”的全域会员健康服务平台',
				id: 2,
				path: '/groupSolution'
			},

			]
		};
	},
	components: {
		Banner,
		ListHead,
		BigHealthyBox,
		ProductSwiper,
		HomeBottomSweiper,
		ContactUs,
		Video,
		HomeNewsBox,
		ContactBox
	},
	mounted() {
		this.HomeBottomSweiperTxt.list1 = this.$store.state.imgLogs.slice(0,22)
		this.HomeBottomSweiperTxt.list2 = this.$store.state.imgLogs.slice(22,44)
		this.HomeBottomSweiperTxt.list3 = this.$store.state.imgLogs.slice(44,64)
	},
	methods: {
		playVide() {
			this.isFlag = true;
		},
		dfsf(isFlag) {
			this.isFlag = isFlag;
		},
		goNewsCenter() {
			this.$router.push('/NewsCenter')
			let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
			var timer = setInterval(() => {
				document.documentElement.scrollTop = document.body.scrollTop = scrollTop -= 500; //一次减50往上滑动
				if (scrollTop <= 0) {
					clearInterval(timer);
				}
			},
				10);
		}
	}
}
</script>
<style lang="less" scoped>
.home {
	width: 100vw;
	min-height: 100vh;
	background: #FFFFFF;
}

.cunstom-m {
	background: #F9FAFE;
	padding-bottom: 60px;
}

.product-m {
	width: 100%;
	background: #F6F9FE;
	padding-bottom: 62px;
}

/* 公司简介 */
.company-profile {
	width: 100%;
	height: auto;
	background: url(http://romenstech.oss-cn-qingdao.aliyuncs.com/shop/88-100/singleimg/16685805123.png) no-repeat center;
	background-size: cover;
	padding-top: 80px;
	padding-bottom: 45px;

	h2 {
		font-size: 48px;
		font-weight: bold;
		color: #FFFFFF;
		line-height: 48px;
		text-align: center;
	}

	p {
		width: 700px;
		font-size: 24px;
		font-weight: 400;
		color: #FFFFFF;
		line-height: 34px;
		margin: 40px auto 0;
	}

	.video-bg {
		width: 670px;
		height: 376px;
		margin: 36px auto 0;
		background: url(http://romenstech.oss-cn-qingdao.aliyuncs.com/shop/88-100/singleimg/166916989711.png) no-repeat center;
		background-size: cover;
		display: flex;
		justify-content: center;
		align-items: center;

		img {
			display: block;
			width: 150px;
			height: 150px;
		}
	}

	// .profile-video {
	// 	width: 542px;
	// 	height: 304px;
	// 	background: #082037;
	// 	margin: 36px auto 0;
	// 	position: relative;

	// 	video {
	// 		width: 100%;
	// 		height: 100%;
	// 	}

	// 	img {
	// 		display: block;
	// 		width: 75px;
	// 		height: 75px;
	// 		transition: all 0.5s ease-out 0s;
	// 		position: absolute;
	// 		margin: auto;
	// 		left: 0;
	// 		right: 0;
	// 		top: 0;
	// 		bottom: 0;
	// 	}
	// }
}

.moreNews {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 212px;
	height: 74px;
	border: 2px solid #DCDCDC;
	border-radius: 10px;
	margin: 50px 0 48px;
	margin-left: 40px;

	span {
		font-size: 30px;
		font-weight: 400;
		color: #222932;

		&:nth-child(1) {
			margin-right: 10px;
		}
	}
}
</style>
