<template>
    <div class="returnPageBox" v-if="isTop">
		<div class="returnPageItem" @click="choaseTap()">
            <img class="img" src="http://romenstech.oss-cn-qingdao.aliyuncs.com/shop/88-100/singleimg/1677650781000.png" />
            <p>TOP</p>
		</div>
    </div>
    <div class="oferPage" v-if="isBack">
        <div class="oferPageItem" @click="ogAppPage()">
            <img class="oferPageImg" src="http://romenstech.oss-cn-qingdao.aliyuncs.com/shop/88-100/singleimg/1677650781000.png" />
		</div>
    </div>
</template>
<script>
    import { wxH5Share } from "../../../assets/common/utils"
	export default {
        name: "ReturnPage",
        props: {
            isTop: {
				type: Boolean,
				required: true,
            },
            isBack: {
				type: Boolean,
				required: true,
			},
        },
        created() {
            if (this.isBack) {
                wxH5Share('你好，我是雨诺')
            }
        },
        data() {
            return {
                choaseNum: null
            }
        },
        methods: {
			choaseTap(type) {
				let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
                console.log(scrollTop,'scrollTop')
				var timer = setInterval(() => {
						document.documentElement.scrollTop = document.body.scrollTop = scrollTop -= 50; //一次减50往上滑动
						if (scrollTop <= 0) {
							this.choaseNum = null;
							clearInterval(timer);
						}
					},
				10);
            },
            ogAppPage() {
                this.$router.push('/AppHomepage')
            }
        }
    }
</script>
<style lang="less" scoped>
.returnPageBox {
    position: fixed;
    right: 32px;
	bottom: 100px;
    z-index: 7;
    .returnPageItem {
		width: 85px;
        height: 85px;
        background: #ffffff;
        box-shadow: 1px 1px 10px #ccc;
        border-radius: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .img {
            display: block;
            width: 31px;
            height: 21px;
	    }
        p{
            color: #276FF5;
            font-weight: 700;
        }
	}
}
.oferPage {
    position: fixed;
    left: 20px;
	bottom: 500px;
    z-index: 7;
    .oferPageItem {
        width: 75px;
        height: 75px;
        background: #ffffff;
        box-shadow: 1px 1px 10px #ccc;
        border-radius: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .oferPageImg {
            display: block;
            width: 35px;
            height: 25px;
            transform: rotate(-90deg);
        }
    }
}
</style>