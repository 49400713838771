<template>
	<div class="list-head">
		<div class="product-introduction">
			<h1 class="modular-title"
				:style="color=='white'? 'color:#ffffff;' : 'color:#222A32;'">
				{{title}}</h1>
			<p class="product-introduction-p" :style="bigWidth=='yes'?'width:8.66667rem;':'width: 6.62667rem;'">{{textDetial}}</p>
		</div>
	</div>
</template>

<script>
	export default {
		props: ["title", "textDetial", "color", "bigWidth"],
		name: 'ListHead',
		data() {
			return {};
		},
	}
</script>

<style lang="less" scoped>
	.list-head {
		padding-top: 80px;
		.modular-title {
			width: 444px;
			font-size: 48px;
			font-weight: bold;
			color: #222A32;
			line-height: 58px;
			margin: 0 auto 50px;
			text-align: center;
		}

		.product-introduction-p {
			width: 497px;
			font-size: 24px;
			font-weight: 400;
			color: #777777;
			line-height: 34px;
			text-align: left;
			margin: 0 auto;
		}
	}
</style>
