// request.js
import axios from 'axios'
const instance =  axios.create({
    // 全局统一的接口服务器地址
    baseURL:"/api",
    // 统一配置请求超时
    timeout:5000
})
// 设置请求拦截器
//  会在请求发送出去之前执行
instance.interceptors.request.use(config=>{
    // config 就是我们所有设置在axios(config) url.type都在config里面
    // 当我们请求的是获取短信验证码的时候，请求头要求是urlencoded
    if(config.url ==='/sendSMS'|| config.url === '/phoneRegin' || config.url === '/wechatUsers/PCLogin'){
        config.headers['Content-Type']='application/x-www-form-urlencoded'
    }
    const token = localStorage.getItem('x-auth-token')
    if(token){
        config.headers['x-auth-token']=token
    }
    return config
})
// 响应拦截
instance.interceptors.response.use(res=>{
    return res.data
})
export default instance
