<template>
	<div class="news-box">
		<div class="news-box-left" @click="goPage(boxList.path)">
			<div class="img-box">
				<img :src="boxList.img" alt="">
			</div>
			<span>{{boxList.time}}</span>
			<h2>{{boxList.title}}</h2>
		</div>
		<div class="news-box-right">
			<ul>
				<li v-for="(item,index) in boxList.list" :key="index" @click="goPage(item.path)">
					<div class="time-box">
						<div class="dian"></div>
						<span>{{item.time}}</span>
					</div>
					<h2>{{item.title}}</h2>
				</li>
			</ul>
		</div>

	</div>
</template>

<script>
	export default {
		props: ["boxList"],
		name: 'ListBox',
		data() {
			return {};
		},
		methods: {
			goPage(src) {
				this.$router.push(src)
				let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
				var timer = setInterval(() => {
						document.documentElement.scrollTop = document.body.scrollTop = scrollTop -= 500; //一次减50往上滑动
						if (scrollTop <= 0) {
							clearInterval(timer);
						}
					},
				10);
			}
		}
	};
</script>

<style lang="less" scoped>
	.news-box {
		background: #ffffff;

		.news-box-left {
			text-align: left;
			width: 670px;
			margin: 0 auto;
			padding-bottom: 40px;

			.img-box {
				width: 670px;
				height: 376px;
				overflow: hidden;

				img {
					display: block;
					width: 670px;
					height: 376px;
				}
			}

			span {
				display: inline-block;
				font-size: 30px;
				font-weight: 400;
				color: #575D6C;
				line-height: 30px;
				margin: 60px 0 44px;
			}

			h2 {
				font-size: 34px;
				font-weight: bold;
				color: #222932;
				line-height: 50px;
			}
		}
	}


	.news-box-right ul {
		width: 670px;
		margin: 0 auto;

		li {
			padding: 30px 0;
			border-top: 1px solid #DCDCDC;
			cursor: pointer;

			.time-box {
				overflow: hidden;
				margin-bottom: 24px;
				display: flex;
				align-items: center;

				.dian {
					width: 12px;
					height: 12px;
					background: #575D6C;
					float: left;
					margin-right: 18px;
				}

				span {
					display: inline-block;
					font-size: 30px;
					font-weight: 400;
					color: #575D6C;
					line-height: 30px;
					float: left;
				}
			}

			h2 {
				padding: 0 30px;
				font-size: 30px;
				font-weight: 500;
				color: #222932;
				line-height: 48px;
			}
		}
	}
</style>
