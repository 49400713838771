<template>
	<div class="cooperative-customers">
		<div class="swiper-container banner-bottom-swiper-container swiper-no-swiping" id="swiper3">
			<div class="swiper-wrapper">
				<div class="swiper-slide" v-for="(item,index) in boxList.list1" :key="index">
					<div class="imgBox">
						<img :src="item.img" alt="">
					</div>
				</div>
			</div>
		</div>
		<div class="swiper-container banner-bottom-swiper-container1 swiper-no-swiping" id="swiper4" dir="rtl">
			<div class="swiper-wrapper">
				<div class="swiper-slide" v-for="(item,index) in boxList.list2" :key="index">
					<div class="imgBox">
						<img :src="item.img" alt="">
					</div>
				</div>
			</div>
		</div>
		<div class="swiper-container banner-bottom-swiper-container2 swiper-no-swiping" id="swiper5">
			<div class="swiper-wrapper">
				<div class="swiper-slide" v-for="(item,index) in boxList.list3" :key="index">
					<div class="imgBox">
						<img :src="item.img" alt="">
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import Swiper from "swiper";
	import "swiper/css/swiper.min.css";
	export default {
		name: "HomeBottomSwiper",
		props: ["boxList"],
		mounted() {
			this.$nextTick(() => {
				this.initSwiper()
			})
		},
		methods: {
			initSwiper() {
				setTimeout(() => {
					new Swiper('#swiper3', {
						slidesPerView: 3.2,
						speed: 4500, //匀速时间
						loop: true, //无缝滑动
						autoplay: {
							delay: 0,
							stopOnLastSlide: false,
							disableOnInteraction: true,
						},
					})
					new Swiper('#swiper4', {
						slidesPerView: 3.2,
						speed: 4500, //匀速时间
						loop: true, //无缝滑动
						autoplay: {
							delay: 0,
							stopOnLastSlide: false,
							disableOnInteraction: true,
						},
					})
					new Swiper('#swiper5', {
						slidesPerView: 3.2,
						speed: 4500, //匀速时间
						loop: true, //无缝滑动
						autoplay: {
							delay: 0,
							stopOnLastSlide: false,
							disableOnInteraction: true,
						},
					})
				}, 300)
			}
		}
	}
</script>
<style lang="less" scoped>
	.cooperative-customers {
		width: 700px;
		margin: 0 auto;
		background: #F6F9FE;
		.imgBox {
			width: 191px;
			box-shadow: 5px 5px 5px rgba(192, 198, 207, 0.3);
			border-radius: 10px;
			overflow: hidden;
			img {
				display: block;
				width: 100%;
			}
		}
	}

	.banner-bottom-swiper-container .swiper-wrapper,
	.banner-bottom-swiper-container1 .swiper-wrapper,
	.banner-bottom-swiper-container2 .swiper-wrapper {
		transition-timing-function: linear;
	}

	.banner-bottom-swiper-container,
	.banner-bottom-swiper-container1,
	.banner-bottom-swiper-container2 {
		margin-bottom: 22px;
	}
</style>
